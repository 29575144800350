import ArrowBlackUp from './dropdown_close_black.svg'
import ArrowRedUp from './dropdown_close_red.svg'
import ArrowBlackDown from './dropdown_open_black.svg'
import ArrowRedDown from './dropdown_open_red.svg'
import Expand from './expand.svg'
import ExpandTourSpots from './expand_tour_spots.svg'
import TourLocker from './locker.svg'
import PauseButtonBlack from './pause_black.svg'
import PayPalGrayLogo from './paypal_grey_logo.svg'
import PlayButtonBlack from './play_black.svg'
import PlusIcon from './plus_icon.svg'
import ProductTicketIcon from './products/ticket/ic_ticket.svg'
import ProductTourIcon from './products/tour/ic_tour.svg'
import ProductVoucherIcon from './products/voucher/ic_voucher.svg'
import PurchaseBadge from './purchase_badge.svg'
import ScrollMore from './scroll_more.svg'
import CategoryFilterEmoji from './searchEmoji.svg'
import SpotLightBlue from './spot_light_blue.svg'
import StarRatingThankYou from './star_rating_thank_you.svg'
import WorkHoursInfo from './time_icon.svg'
import TourAppStoreButton from './tours/appstore.svg'
import TourGooglePlayButton from './tours/googleplay.svg'
import TourArtWorks from './tours/ic_artworks.svg'
import TourAudioGuide from './tours/ic_audioguide.svg'
import TourBeacons from './tours/ic_beacons.svg'
import TourDuration from './tours/ic_duration.svg'
import TourEarphones from './tours/ic_earphones.svg'
import Headphones from './tours/ic_headphones.svg'
import TourIndoor from './tours/ic_indoor.svg'
import TourLanguageIcon from './tours/ic_language.svg'
import TourLock from './tours/ic_lock.svg'
import TourNavigation from './tours/ic_navigation.svg'
import TourOutdoor from './tours/ic_outdoor.svg'
import TourReloadIcon from './tours/ic_reload.svg'
import TourFacebook from './tours/ic_social_face.svg'
import TourShare from './tours/ic_social_share.svg'
import TourTwitter from './tours/ic_social_twiter.svg'
import TourSpots from './tours/ic_spots.svg'
import TourTicket from './tours/ic_ticket.svg'
import TourTicketBigWhite from './tours/ic_ticket_big_white.svg'
import TourTicketBold from './tours/ic_ticket_bold.svg'
import TourVoucher from './tours/ic_voucher.svg'

import TicketMinus from '../../assets/icons/ticket/ticket-minus.svg'
import TicketPlus from '../../assets/icons/ticket/ticket-plus.svg'
import AboutUsArrowWhite from '../icons/arrow_white.svg'
import PodcastHeaderIcon1 from '../icons/podcast/header_icon1.svg'
import PodcastHeaderIcon2 from '../icons/podcast/header_icon2.svg'
import PodcastHeaderImg from '../icons/podcast/header_img.svg'
import ThankYouAward from '../icons/thank-you-award.svg'
import IndoorMusicIcon from '../icons/tours/indoor_music.svg'
import IndoorVideoIcon from '../icons/tours/indoor_video.svg'

import SpotsAddPlusBlue from './add_spots_plus_blue.svg'
import SpotsRemoveGray from './add_spots_remove_grey.svg'
import AlertCircle from './alert-circle.svg'
import Audio from './audio.svg'
import AudioActive from './audio_active.svg'
import BigAudio from './bigNote.svg'
import CartItemRemove from './checkout/cart-item-remove.svg'
import CheckoutBackArrow from './checkout/cp_back_arrow.svg'
import CheckoutBackArrowWhite from './checkout/cp_back_arrow_white.svg'
import ButtonLock from './checkout/cp_button_lock.svg'
import CartBig from './checkout/cp_cart_big.svg'
import CheckoutLogin from './checkout/cp_checkout_login.svg'
import CheckoutSignUp from './checkout/cp_checkout_signup.svg'
import CheckoutContinueArrow from './checkout/cp_continue_arrow_white.svg'
import CheckoutCountrySearch from './checkout/ic_country_search.svg'
import CloseIconSmall from './close-icon-small.svg'
import CloseModal from './close_modal.svg'
import ListingActions from './cmsListing/actions.svg'
import ListingPreview from './cmsListing/preview.svg'
import EmailContact from './contact/email_white.svg'
import LocationContact from './contact/location_white.svg'
import ArrowBack from './cp_arrow_back.svg'
import ArrowBackFull from './cp_arrow_back_full.svg'
import ArrowForward from './cp_arrow_forward.svg'
import ArrowForwardFull from './cp_arrow_forward_full.svg'
import CPChevronDown from './cp_chevron_down.svg'
import CPChevronUp from './cp_chevron_up.svg'
import ContactUs from './cp_contact.svg'
import ContactUsSelected from './cp_contact_selected.svg'
import EnterFullScreen from './cp_enter_fullscreen.svg'
import ExitFullScreen from './cp_exit_fullscreen.svg'
import CpFacebook from './cp_facebook.svg'
import Home from './cp_home.svg'
import HomeSelected from './cp_home_selected.svg'
import CPIcon from './cp_icon.svg'
import CPLogoGlobe from './cp_icon_globe.svg'
import CpInstagram from './cp_instagram.svg'
import CpLinkedIn from './cp_linkedin.svg'
import AddressInfo from './cp_location.svg'
import Logo from './cp_logo.svg'
import LogoWhite from './cp_logo_white.svg'
import Map from './cp_map.svg'
import MapCard from './cp_map_card.svg'
import MapSelected from './cp_map_selected.svg'
import SideDrawerToggle from './cp_menu.svg'
import SideDrawerToggleNew from './cp_menu_new.svg'
import SideDrawerToggleWhite from './cp_menu_white.svg'
import OrdersCartUniversal from './cp_orders_cart.svg'
import Search from './cp_search_icon.svg'
import SearchSelected from './cp_search_selected.svg'
import SearchWhite from './cp_search_white.svg'
import Stories from './cp_stories.svg'
import StoriesSelected from './cp_stories_selected.svg'
import Support from './cp_support.svg'
import SupportSelected from './cp_support_selected.svg'
import CpTwitter from './cp_twitter.svg'
import Verified from './cp_verified.svg'
import FBIcon from './fb_icon.svg'
import AppStoreButton from './footer/appstore.svg'
import FooterFacebook from './footer/facebook.svg'
import GooglePlayButton from './footer/googleplay.svg'
import FooterInstagram from './footer/instagram.svg'
import FooterTwitter from './footer/twitter.svg'
import Foursquare from './foursquare_icon.svg'
import FreeTicketGreen from './freeTicketGreen.svg'
import GlobeIcon from './globe_icon.svg'
import GlobeLightBlue from './globe_light_blue.svg'
import GoogleIcon from './google_icon.svg'
import HeadphonesLightBlue from './headphones_light_blue.svg'
import HideTourSpots from './hide_tour_spots.svg'
import DarkAppStore from './home/AppStoreDark.svg'
import DarkGooglePlay from './home/GooglePlayDark.svg'
import HomeDiscoverGuides from './home_discover_guides_icon.svg'
import HomeExplorePlaces from './home_explore_places_icon.svg'
import HomePlayButtonIcon from './home_play_icon.svg'
import GetStartedHorizontalIcon from './horizontal_line.svg'
import HostMobileMenu from './host_mobile_menu.svg'
import HowItWorksOne from './howItWorksSlider/how_it_works1.svg'
import HowItWorksTwo from './howItWorksSlider/how_it_works2.svg'
import HowItWorksThree from './howItWorksSlider/how_it_works3.svg'
import Activity from './ic_activity.svg'
import ArrowNextWhite from './ic_arrow_next_white.svg'
import ArrowBackGrey from './ic_back_grey.svg'
import ArrowBackWhite from './ic_back_white.svg'
import BrushInactive from './ic_brush.svg'
import BrushActive from './ic_brush_selected.svg'
import CameraDark from './ic_camera_dark.svg'
import CameraSmallDark from './ic_camera_small_dark.svg'
import CameraSmallWhite from './ic_camera_small_white.svg'
import CameraWhite from './ic_camera_white.svg'
import CaptureSmall from './ic_capture.svg'
import CheckboxActive from './ic_checkbox_active.svg'
import CheckboxInactive from './ic_checkbox_inactive.svg'
import ChevronDown from './ic_chevron_down.svg'
import Close from './ic_close.svg'
import CloseDark from './ic_close_dark.svg'
import CloseDarkBold from './ic_close_dark_bold.svg'
import EnvelopeWhite from './ic_envelope_white.svg'
import Event from './ic_event.svg'
import FacebookInfo from './ic_facebook.svg'
import Filter from './ic_filter.svg'
import FilterHide from './ic_filter_hide.svg'
import FilterSelected from './ic_filter_selected.svg'
import FilterShow from './ic_filter_show.svg'
import FilterUnselected from './ic_filter_unselected.svg'
import Filters from './ic_filters.svg'
import ArrowForwardGrey from './ic_forward_grey.svg'
import ArrowForwardWhite from './ic_forward_white.svg'
import HelpingWithCulturalPlacesIcon from './ic_helping_with_cultural_places.svg'
import HideVideoSlider from './ic_hide_white.svg'
import InstagramInfo from './ic_instagram.svg'
import Institution from './ic_institution.svg'
import Landmark from './ic_landmark.svg'
import WebInfo from './ic_link.svg'
import LogOut from './ic_log_out.svg'
import LogOutNew from './ic_log_out_new.svg'
import LogOutWhite from './ic_log_out_white.svg'
import ArrowNext from './ic_next_place.svg'
import PayPalIcon from './ic_paypal.svg'
import PhoneInfo from './ic_phone.svg'
import ArrowPrevious from './ic_previous_place.svg'
import Profile from './ic_profile.svg'
import ProfileLocation from './ic_profile_location.svg'
import ProfileMail from './ic_profile_mail.svg'
import OpenInApp from './ic_profile_open_in_app.svg'
import ProfileOrders from './ic_profile_orders.svg'
import ProfileArrow from './ic_profile_right_arrow.svg'
import ProfileSelected from './ic_profile_selected.svg'
import ProfileTime from './ic_profile_time.svg'
import ProfileVoucherShape from './ic_profile_voucher_shape.svg'
import ProfileWhite from './ic_profile_white.svg'
import SearchLight from './ic_search_lightgrey.svg'
import Settings from './ic_settings.svg'
import SettingsNew from './ic_settings_new.svg'
import SettingsSelected from './ic_settings_selected.svg'
import SettingsWhite from './ic_settings_white.svg'
import Share from './ic_share.svg'
import Shop from './ic_shop.svg'
import Star from './ic_star.svg'
import TicketIcon from './ic_ticket.svg'
import TicketIconGray from './ic_ticket_gray.svg'
import TwitterInfo from './ic_twitter.svg'
import UploadSmall from './ic_upload.svg'
import ProfileNew from './ic_user_new.svg'
import WarningOrange from './ic_warning.svg'
import WikipediaInfo from './ic_wiki.svg'
import IconsDownloadBanner from './icons_download_banner.svg'
import MapCheckboxActive from './map_checkbox_active.svg'
import MapCheckboxInactive from './map_checkbox_inactive.svg'
import MinusIcon from './minus_icon.svg'
import MuteIcon from './mute.svg'
import OrdersCart from './orders_cart.svg'
import OrdersCartColored from './orders_cart_colored.svg'
import Video from './video.svg'
import VideoCameraLightBlue from './video_camera_blue.svg'
import VideoCamera from './video_camera_icon.svg'
import VideoIcon from './video_play_icon.svg'
import VolumeIcon from './volume.svg'
import VoucherWhite from './voucher_white.svg'

const icons = {
  SpotsAddPlusBlue: SpotsAddPlusBlue,
  SpotsRemoveGray: SpotsRemoveGray,
  thankYouAward: ThankYouAward,
  AlertCircle: AlertCircle,
  audio: {
    active: AudioActive,
    normal: Audio,
    large: BigAudio,
    play: {
      black: PlayButtonBlack
    },
    pause: {
      black: PauseButtonBlack
    }
  },
  video: {
    normal: Video
  },
  videoCamera: {
    icon: VideoCamera,
    lightBlue: VideoCameraLightBlue
  },
  cpIcon: CPIcon,
  logo: {
    normal: Logo,
    white: LogoWhite,
    globe: CPLogoGlobe
  },
  search: {
    normal: Search,
    selected: SearchSelected,
    white: SearchWhite,
    light: SearchLight
  },
  home: {
    normal: Home,
    selected: HomeSelected,
    getStarted: {
      horizontal: GetStartedHorizontalIcon
    },
    discoverGuides: HomeDiscoverGuides,
    explorePlaces: HomeExplorePlaces,
    store: {
      GooglePlay: DarkGooglePlay,
      AppStore: DarkAppStore
    },
    homePlayButton: HomePlayButtonIcon
  },
  map: {
    normal: Map,
    selected: MapSelected
  },
  mapCard: {
    normal: MapCard,
    selected: MapCard
  },
  helpingWithCulturalPlaces: {
    normal: HelpingWithCulturalPlacesIcon,
    selected: HelpingWithCulturalPlacesIcon
  },
  contactUs: {
    normal: ContactUs,
    selected: ContactUsSelected
  },
  stories: {
    normal: Stories,
    selected: StoriesSelected
  },
  support: {
    normal: Support,
    selected: SupportSelected
  },
  sideDrawerToggle: {
    normal: SideDrawerToggle,
    white: SideDrawerToggleWhite
  },
  sideDrawerToggleNew: {
    normal: SideDrawerToggleNew
  },
  hostMobileMenu: {
    normal: HostMobileMenu
  },
  foursquare: {
    normal: Foursquare
  },
  place: {
    verified: Verified,
    foursquare: Foursquare
  },
  purchase: {
    badge: PurchaseBadge,
    ordersCartColored: OrdersCartColored,
    ordersCart: OrdersCart
  },
  arrows: {
    previous: ArrowPrevious,
    next: {
      black: ArrowNext,
      white: ArrowNextWhite
    },
    back: {
      black: ArrowBack,
      grey: ArrowBackGrey,
      white: ArrowBackWhite
    },
    forward: {
      black: ArrowForward,
      grey: ArrowForwardGrey,
      white: ArrowForwardWhite
    },
    down: {
      red: ArrowRedDown,
      black: ArrowBlackDown
    },
    up: {
      red: ArrowRedUp,
      black: ArrowBlackUp
    },
    full: {
      back: {
        black: ArrowBackFull
      },
      forward: {
        black: ArrowForwardFull
      }
    },
    aboutUs: {
      white: AboutUsArrowWhite
    }
  },
  workHours: {
    info: WorkHoursInfo
  },
  phone: {
    info: PhoneInfo
  },
  web: {
    info: WebInfo
  },
  google: {
    login: GoogleIcon
  },
  facebook: {
    login: FBIcon,
    info: FacebookInfo
  },
  twitter: {
    info: TwitterInfo
  },
  instagram: {
    info: InstagramInfo
  },
  wikipedia: {
    info: WikipediaInfo
  },
  address: {
    info: AddressInfo
  },
  share: {
    info: Share
  },
  footer: {
    social: {
      instagram: FooterInstagram,
      twitter: FooterTwitter,
      facebook: FooterFacebook
    },
    store: {
      googlePlay: GooglePlayButton,
      appStore: AppStoreButton
    }
  },
  contact: {
    email: EmailContact,
    location: LocationContact
  },
  chevron: {
    down: ChevronDown
  },
  cpChevron: {
    down: CPChevronDown,
    up: CPChevronUp
  },
  iconsDownloadBanner: {
    normal: IconsDownloadBanner
  },
  paypal: {
    grayLogo: PayPalGrayLogo,
    normal: PayPalIcon,
    ticket: TicketIcon,
    ticket_gray: TicketIconGray,
    minus: MinusIcon,
    plus: PlusIcon
  },
  institution: {
    normal: Institution
  },
  event: {
    normal: Event
  },
  landmark: {
    normal: Landmark
  },
  shop: {
    normal: Shop
  },
  activity: {
    normal: Activity
  },
  filter: {
    normal: Filter,
    hide: FilterHide,
    show: FilterShow,
    selected: FilterSelected,
    unselected: FilterUnselected
  },
  filters: {
    normal: Filters
  },
  close: {
    normal: Close,
    dark: CloseDark,
    dark_bold: CloseDarkBold,
    modal: CloseModal,
    small: CloseIconSmall
  },
  star: {
    normal: Star,
    ratingThankYou: StarRatingThankYou
  },
  logOut: {
    normal: LogOut,
    white: LogOutWhite,
    new: LogOutNew
  },
  profile: {
    normal: Profile,
    new: ProfileNew,
    selected: ProfileSelected,
    white: ProfileWhite,
    location: ProfileLocation,
    mail: ProfileMail,
    orders: {
      grey: ProfileOrders,
      cart: OrdersCartUniversal
    },
    time: ProfileTime,
    arrow_right: ProfileArrow,
    open_in_app: OpenInApp,
    voucher_shape: ProfileVoucherShape
  },
  player: {
    mute: MuteIcon,
    unmute: VolumeIcon
  },
  settings: {
    normal: Settings,
    selected: SettingsSelected,
    white: SettingsWhite,
    new: SettingsNew
  },
  tours: {
    duration: TourDuration,
    navigation: TourNavigation,
    spots: TourSpots,
    indoor: TourIndoor,
    outdoor: TourOutdoor,
    beacons: TourBeacons,
    audioguide: TourAudioGuide,
    artworks: TourArtWorks,
    reload: TourReloadIcon,
    language: TourLanguageIcon,
    locker: TourLocker,
    lock: TourLock,
    googlePlay: TourGooglePlayButton,
    appStore: TourAppStoreButton,
    expandSpots: ExpandTourSpots,
    hideSpots: HideTourSpots,
    earphones: TourEarphones,
    headphones: Headphones,
    voucher: TourVoucher,
    ticket: TourTicket,
    ticketBold: TourTicketBold,
    ticketBigWhite: TourTicketBigWhite,
    videoIcon: VideoIcon,
    indoorMusic: IndoorMusicIcon,
    indoorVideo: IndoorVideoIcon,
    social: {
      share: TourShare,
      facebook: TourFacebook,
      twitter: TourTwitter
    }
  },
  camera: {
    white: CameraWhite,
    dark: CameraDark,
    small: {
      white: CameraSmallWhite,
      dark: CameraSmallDark
    }
  },
  capture: {
    small: {
      normal: CaptureSmall
    }
  },
  upload: {
    small: {
      normal: UploadSmall
    }
  },
  envelope: {
    white: EnvelopeWhite
  },
  checkbox: {
    active: CheckboxActive,
    inactive: CheckboxInactive,
    mapActive: MapCheckboxActive,
    mapInactive: MapCheckboxInactive
  },
  brush: {
    normal: BrushInactive,
    selected: BrushActive
  },
  warning: {
    orange: WarningOrange
  },
  howItWorksSlider: [HowItWorksOne, HowItWorksTwo, HowItWorksThree],
  checkout: {
    cart: {
      big: CartBig
    },
    lock: ButtonLock,
    continue: CheckoutContinueArrow,
    back: {
      light: CheckoutBackArrowWhite,
      dark: CheckoutBackArrow
    },
    login: CheckoutLogin,
    signup: CheckoutSignUp,
    countrySearch: CheckoutCountrySearch,
    cartItemRemove: CartItemRemove
  },
  expand: {
    normal: Expand,
    scrollMore: ScrollMore,
    enterFullscreen: EnterFullScreen,
    exitFullscreen: ExitFullScreen
  },
  voucher: VoucherWhite,
  eyeHide: HideVideoSlider,
  product: {
    tour: {
      icon: ProductTourIcon
    },
    voucher: {
      icon: ProductVoucherIcon
    },
    ticket: {
      icon: ProductTicketIcon
    }
  },
  ticket: {
    freeTicket: {
      green: {
        icon: FreeTicketGreen
      }
    },
    minus: TicketMinus,
    plus: TicketPlus
  },
  cpInstagram: {
    icon: CpInstagram
  },
  cpFacebook: {
    icon: CpFacebook
  },
  cpTwitter: {
    icon: CpTwitter
  },
  cpLinkedIn: {
    icon: CpLinkedIn
  },
  podcast: {
    headerImg: PodcastHeaderImg,
    headerIcon1: PodcastHeaderIcon1,
    headerIcon2: PodcastHeaderIcon2
  },
  categoryFilterEmoji: {
    icon: CategoryFilterEmoji
  },
  globe: {
    lightBlue: GlobeLightBlue
  },
  headphones: {
    lightBlue: HeadphonesLightBlue
  },
  spot: {
    lightBlue: SpotLightBlue
  },
  cmsListing: {
    actions: ListingActions,
    preview: ListingPreview
  },
  globeIcon: GlobeIcon
}

export default icons
